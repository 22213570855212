// Styles
import './style.scss';

// React components
import React, { HTMLProps, useState } from 'react';
import { Link as LinkRouter } from "react-router-dom"
import { Link as LinkScroll, animateScroll } from "react-scroll"

import logo from '../../image/logo/logo.png';

type MenuLinksProps = {
  destination: string,
  isSmooth: boolean
  duration: number,
  pageName: string,
  toggleFunction: () => void,
} & HTMLProps<HTMLInputElement>;


const MenuLink = ({ destination, isSmooth, duration, pageName, toggleFunction }: MenuLinksProps) => {
  return ( 
    <LinkScroll
        offset={-25}
        to={ destination }
        smooth={ isSmooth }
        duration={ duration }
        onClick={toggleFunction}>
        { pageName }
    </LinkScroll>
  )
}

type Props = {
  mode: string,
} & HTMLProps<HTMLInputElement>;

// Actuall function
const Navbar = ({ mode }: Props) => {
  const [expanded, setExpanded] = useState(false);

  function toggleMenu(): void{
    setExpanded(!expanded);
  }

  return (
    <div  id="navbar" className={`${mode === 'main-page' ? 'navbar-fixed' : 'navbar-static'}`}>
      <div className="container">
        <div className="logo-container">
          <LinkRouter to="/" onClick={() => animateScroll.scrollToTop()}>
            <img src={ logo } alt="Not found" />
          </LinkRouter>
        </div>
        <input id="menu-toggle" type="checkbox" checked={expanded} onChange={toggleMenu}/>
        <label className="menu-button-container" htmlFor="menu-toggle">
          <div className="menu-button"></div>
        </label>

        <div className="links-container">
          {
            mode === 'main-page' && 
            <>
              <MenuLink
                destination='about-me-section'
                isSmooth={true}
                duration={400}
                toggleFunction={toggleMenu}
                pageName='O nas'/>
              <MenuLink destination='offert-section' isSmooth={true} duration={400} toggleFunction={toggleMenu} pageName='Oferta'/>
              <MenuLink destination='opinions-section' isSmooth={true} duration={400} toggleFunction={toggleMenu} pageName='Opinie'/>
              <MenuLink destination='contact-section' isSmooth={true} duration={400} toggleFunction={toggleMenu} pageName='Kontakt'/>
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default Navbar;