// Styles
import './style.scss';

import React, { HTMLProps } from 'react';

import star from '../../../../image/icons/star.png';

type OpinionProps = {
  index: number,
  name: string,
  image?: string,
  month: string,
  year: number
  text: string,
  rate: number,
  color: string,
} & HTMLProps<HTMLInputElement>;


const Opinion = ({ index, name, image, month, year, text, color }: OpinionProps) => {
  return (
    <div className={`opinion ${index === 1 ? 'main' : ''}`}>
      <div className="heading">
        {
          image != null ? 
          <img src={ image } alt="Not found"/>
          :
          <div className="fake-avatar" style={{ backgroundColor: color }}>
            {name[0]}
          </div>
        }

        <div className="introduction">
          <h2>{ name }</h2>
          <h4>{month} {year}</h4>
        </div>
      </div>
      <div className="content">
        { text }
      </div>
      <div className="rating">
        <img src={ star } alt="Not found" />
        <img src={ star } alt="Not found" />
        <img src={ star } alt="Not found" />
        <img src={ star } alt="Not found" />
        <img src={ star } alt="Not found" />
      </div>
    </div>
  )
}

// Actuall function
const Opinions = () => {
  const opinions = [
    {
      name: 'Mario',
      month: 'maj',
      year: 2024,
      text: 'Profesjonalnie, szybko tanio i w koleżeńskiej atmosferze, bardzo gorąco polecam warsztat pana Janka byle więcej takich fachowców co mają taką wiedzę 😉Pacjent A4 B9…',
      rate: 5,
      color: '#56ba88'
    },
    {
      name: 'Krystian P',
      month: 'maj',
      year: 2024,
      text: 'Piszę opinie z perspektywy  roku czasu jazdy na mapie która zrobił pan Janek. Wóz się super prowadził dostał mocy i przez ten czas nic się nie wykrzaczyło. Dzisiaj byłem na hamowni stacjonarnej w firmie X na korektę mapy po modyfikacjach mechanicznych które dokonałem w ostatnim czasie i według gościa który robi samochody od 20 lat wszystko było z poprzednimi ustawieniami ok :) wiec szczerze polecam. I dziękuję za robotę. Pozdrawiam !',
      rate: 5,
      color: '#92aa28'
    },
    {
      name: 'FireShooter',
      month: 'maj',
      year: 2024,
      text: 'Chłopaki robią mega robotę, mogę polecić ich z czystym sumieniem, bardzo fajnie doradzają co można zrobić żeby auto lepiej chodziło, po chiptuningu od razu czuć różnicę a co najważniejsze chłopaki robią to w granicach rozsądku żeby silnik nie wyleciał w powietrze',
      rate: 5,
      color: '#6967d6'
    }
  ]

  return (
    <div id='opinions-section'>
        <div
          className="container"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-once="true"
          data-aos-easing="ease-in-out">
          <div className="heading">
            <h1>Opinie</h1>
          </div>
          <div className="carousel">
            { opinions.map((item,i) =>
              <Opinion
                key={i}
                index={i}
                name={item.name}
                month={item.month}
                year={item.year}
                text={item.text}
                rate={item.rate}
                color={item.color} />) }
          </div>
          <div className="show-more">
            <a 
              className='animated-button' 
              href="https://www.google.com/maps/place/Chiptuning+Garage+-+ADBLUE+%2F+hamownia+%2F+DPF+%2F+EGR+%2F+zwiększ+moc/@50.0890239,20.0481033,16.75z/data=!4m18!1m9!3m8!1s0x4716459b156e1719:0x5fbeb357f53b3101!2sChiptuning+Garage+-+ADBLUE+%2F+hamownia+%2F+DPF+%2F+EGR+%2F+zwiększ+moc!8m2!3d50.0891315!4d20.0473355!9m1!1b1!16s%2Fg%2F11rk9z3zgj!3m7!1s0x4716459b156e1719:0x5fbeb357f53b3101!8m2!3d50.0891315!4d20.0473355!9m1!1b1!16s%2Fg%2F11rk9z3zgj?hl=pl&entry=ttu"
              target="_blank"
              rel="noreferrer">
              Zobacz wszystkie
            </a>
          </div>

        </div>

        <span className="graphic-curve shadow"></span>
        <span className="graphic-curve"></span>
    </div>
  );
}

export default Opinions;