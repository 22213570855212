import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import MainPage from './page/main-page/MainPage';
import PageNotFound from './page/page-not-found/PageNotFound';

function App() {
  AOS.init({
    disable: 'mobile'
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
