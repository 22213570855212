// Styles
import './style.scss';

// React components
import React from 'react';
import Navbar from '../../component/navbar/Navbar';
import { Link } from 'react-router-dom';

// Actuall function
export default function PageNotFound() {

  return (
    <>
      <Navbar mode={'realisation-page'} />
      <div id="page-not-found">
          <div className="container">
            <h1>Error 404</h1>
            <h3>Nie znaleziono żądanej strony</h3>
            <Link to={'/'}> Powrót do strony głównej </Link>
          </div>
      </div>
    </>
  );
}