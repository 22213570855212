// Styles
import './style.scss';

// React components
import React from 'react';
import WelcomeSection from './sections/welcome/WelcomeSection';
import AboutMe from './sections/about-me/AboutMe';
import Offert from './sections/offert/Offert';
import FiveSteps from './sections/5-steps/FiveSteps';
import Opinions from './sections/opinions/Opinions';
import Contact from './sections/contact/Contact';
import Navbar from '../../component/navbar/Navbar';

// Actuall function
const MainPage = () => {

  return (
    <>
      <Navbar mode={'main-page'} />
      <WelcomeSection />
      <AboutMe />
      <Offert />
      <FiveSteps />
      <Opinions />
      <Contact />
    </>
  );
}

export default MainPage;