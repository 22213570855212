// Styles
import './style.scss';

import React, { HTMLProps } from 'react';

import image_1 from '../../../../image/offer/image_1.jpg'

type Props = {
  img: string,
  title: string,
  subtitle: string,
  price: string,
} & HTMLProps<HTMLInputElement>;

const Product = ({ title, img, subtitle, price }: Props) => {
  return(
    <div className="product">
      <img src={ img } alt="Not found" />
      <div className="description">
        <h4>{ title }</h4>
        <h5>{ subtitle } <strong>{ price }</strong></h5>
      </div>
    </div>
  )
}

// Actuall function
const Offert = () => {
  return (
    <div id='offert-section'>
        <div
          className="container"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-once="true"
          data-aos-easing="ease-in-out">
          <div className='image-container'>
            <img src={image_1} className="first" alt="Not found"/>
          </div>
          <div className='text-container'>
            <h1>Oferta Fileservice</h1>
            <h2>
              Do warsztatów tuningowych, warsztatów mechaniki oraz hobbystów modyfikacji.
            </h2>
            <h3>
              Co zyskujesz w porównaniu z innymi serwisami?
            </h3>
            <ol>
              <li>
                <span>Czas</span>
                <br/>
                Sprawne zarządzanie zleceniami sprawia, że wylądowanie w kolejce nie wiąże się z godzinnym oczekiwaniem. Większość zleceń jest realizowana do 40 minut.
              </li>
              <li>
                <span>Sprawdzone rozwiązania</span>
                <br/>
                Na co dzień stroimy samochody z hamownią i bogatą diagnostyką. Rozwiązania które otrzymasz u nas mają poparcie w naszym doświadczeniu. Podobnie z plikami SCR, DPF, EGR - wydaliśmy tysiące pojazdów, więc w Twoim - nasze rozwiązania również się sprawdzą.
              </li>
              <li>
                <span>Korekta po logu</span>
                <br/>
                U nas w cenie masz korektę modyfikacji pod logi dynamiczne jeśli tylko je nam załączysz. Dzięki temu otrzymujesz jakość bliską modyfikacji u nas na miejscu.
              </li>
              <li>
                <span>Cena względem wartości usługi</span>
                <br/>
                Znamy rynek usług zdalnych i wiemy, że niejednokrotnie można słono przepłacić za rozwiązanie przeklejone z innej wersji silnikowej, bądź dezaktywację z removera, który uniemożliwia dalszą diagnostykę samochodu. U nas balans pomiędzy jakością, a ceną jest właściwy co potwierdzają zadowoleni klienci.
              </li>
            </ol>
          </div>
        </div>
        <span className="graphic-curve shadow"></span>
        <span className="graphic-curve"></span>
    </div>
  );
}

export default Offert;