// Styles
import './style.scss';

import React, { HTMLProps } from 'react';

import user_icon from '../../../../image/icons/user.svg';
import pin_icon from '../../../../image/icons/map_pin.svg';
import phone_icon from '../../../../image/icons/phone.svg';
import email_icon from '../../../../image/icons/email.svg';
import time_icon from '../../../../image/icons/clock.svg';

type ContactPropertyProps = {
    image: string,
    text: string[],
} & HTMLProps<HTMLInputElement>;
  

const ContactProperty = ({ image, text }: ContactPropertyProps) => {
    return (
        <div className='property-container'>
            <img src={image} alt="Not found"/>
            <div className="text-values">
                { text.map((item,i) => <p key={i}>{item}</p>) }
            </div>

        </div>
    )
}

type HourPropertyProps = {
    time: string,
    day: string,
    dayCode: number,
} & HTMLProps<HTMLInputElement>;
  

const HourProperty = ({ time, day, dayCode }: HourPropertyProps) => {
    const isToday = (dayCode % 7) === new Date().getDay();

    return (
        <div className='hour-container'>
            <h4 className={`${isToday ? 'today' : ''}`}>{time}</h4>
            <h4 className={`${isToday ? 'today' : ''}`}>{day}</h4>
        </div>
    )
}

// Actuall function
const Contact = () => {
    const contact = [
        {
            image: user_icon,
            content: ["Jan Piszczek"]
        },
        {
            image: pin_icon,
            content: ["Kornela Makuszyńskiego 16,", "31-752 Kraków"]
        },
        {
            image: phone_icon,
            content: ["+48 579 801 049"]
        },
        {
            image: email_icon,
            content: ["chiptuning@garage.hub.pl"]
        }
    ]

    const hours = [
        {
            time: "08:30 - 19:00",
            day: 'poniedziałek'
        },
        {
            time: "08:30 - 19:00",
            day: 'wtorek'
        },
        {
            time: "08:30 - 19:00",
            day: 'środa'
        },
        {
            time: "08:30 - 19:00",
            day: 'czwartek'
        },
        {
            time: "08:30 - 18:00",
            day: 'piątek'
        },
        {
            time: "08:30 - 14:00",
            day: 'sobota'
        },
        {
            time: "nieczynne",
            day: 'niedziela'
        },
    ]

    return (
        <div id='contact-section'>
            <div 
                className="container"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-once="true"
                data-aos-easing="ease-in-out">
                <div className="text-container">
                    <h1>Kontakt</h1>
                    <div className="properties">
                        { contact.map((item,i) => <ContactProperty key={i} image={item.image} text={item.content}/>) }
                    </div>
                    <div className="information">
                        Napisz w SMS-ie: Markę, model, rok produkcji, pojemność silnika, moc fabryczną - do 30 minut otrzymasz wycenę modyfikacji samochodu!
                    </div>
                    <div className="hours">
                        <div className="hours_header">
                            <img src={time_icon} alt="Not found" />
                            <h3>Godziny otwarcia</h3>
                        </div>
   
                        { hours.map((item,i) => <HourProperty key={i} time={item.time} day={item.day} dayCode={i+1} />) }
                    </div>
                    
                </div>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.8480630378635!2d20.04476057723713!3d50.089131471525455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716459b156e1719%3A0x5fbeb357f53b3101!2sChiptuning%20Garage%20-%20ADBLUE%20%2F%20hamownia%20%2F%20DPF%20%2F%20EGR%20%2F%20zwi%C4%99ksz%20moc!5e0!3m2!1spl!2spl!4v1684742715466!5m2!1spl!2spl" 
                        loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <footer>
                Chiptuning Garage &copy; 2024
            </footer>
        </div>
    );
}

export default Contact;