// Styles
import './style.scss';

import React from 'react';

import image_1 from '../../../../image/offer/chart_1.png';
import image_2 from '../../../../image/offer/chart_2.jpg';

// Actuall function
const FiveSteps = () => {
    return (
        <div id='five-steps-section'>
            <div
                className="container"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-once="true"
                data-aos-easing="ease-in-out">
                <div className='text-container'>
                    <h1>Proces 5 kroków</h1>
                    <ol>
                        <li>
                            <span>Prześlij Plik</span>
                            <br/>
                            Prześlij nam plik ECU swojego klienta na mail <b>CHIPTUNING@GARAGE.HUB.PL</b> wraz z prośbą o konkretną modyfikację. Po wycenie dokonaj płatności.
                        </li>
                        <li>
                            <span>Przygotowanie</span>
                            <br/>
                            Wykonaj pomiary mocy lub logi dynamiczne. Jeśli zrobiłeś to przed wysłaniem pliku po prostu dolej sobie kawy :)
                        </li>
                        <li>
                            <span>Pobierz Zmodyfikowany Plik</span>
                            <br/>
                            Po zakończeniu modyfikacji, otrzymasz plik skalibrowany pod Twój egzemplarz.
                        </li>
                        <li>
                            <span>Wgraj modyfikację i wykonaj testy</span>
                            <br/>
                            Po testach drogowych możesz dosłać nam diagnostykę, zweryfikujemy ją i jeśli warto będzie nanieść korektę dla lepszej efektywności - otrzymasz ją w kilka minut!
                        </li>
                        <li>
                            <span>Samochód gotowy do wydania!</span>
                            <br/>
                            Cieszą nas relacje naszych klientów dotyczące wrażeń z jazdy - Twoi klienci na pewno też odczują radość z jazdy, jednocześnie czując spokój o solidnie zrobioną robotę.                        </li>
                    </ol>
                </div>
                <div className='image-container'>
                    <img src={image_1} className="first" alt="Not found"/>
                    <img src={image_2} className="second" alt="Not found"/>
                </div>
            </div>
            <span className="graphic-curve shadow"></span>
            <span className="graphic-curve"></span>
        </div>
    );
}

export default FiveSteps;