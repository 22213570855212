// Styles
import './style.scss';

import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGoogle, faFacebook, faTiktok } from "@fortawesome/free-brands-svg-icons"

// import tools_image from '../../../../image/car-workshop-tools.jpg';
import image_1 from '../../../../image/about_me/image_1.jpg'
import image_2 from '../../../../image/about_me/image_2.jpg'
import image_3 from '../../../../image/about_me/image_3.jpg'

// Actuall function
const AboutMe = () => {

    return (
        <div id='about-me-section'>
            <div
                className="container"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-once="true"
                data-aos-easing="ease-in-out">
                <div className="text-container">
                    <h1>O nas</h1>
                    <p>
                        Wiemy, że indywidualne podejście do każdego projektu to konieczność. Nie marnujemy czasu swojego i klienta - na wgrywanie powtarzalnych rozwiązań, które okazują się po czasie nie działać  lub nieść szkodę. Każdy samochód, który trafia do naszego warsztatu, przechodzi dokładną diagnostykę, aby dobrać nową kalibrację do realnych możliwości i potrzeb. Celem potwierdzenia tego statutu zapraszamy do lektury opinii na platformie Google. Realizacje z wykresami mocy i momentu obrotowego umieszczamy na profilu facebook.                  
                    </p>
                    <p>
                        Niezależnie czy masz silnik 1.4l czy 3.5l możemy odmienić twoje wrażenia z jazdy na więcej radości przy jednoczesnym zachowaniu rozsądnych parametrów pracy silnika
                    </p>
                    <div className="social-links">
                        <a className='google-link' href="https://maps.app.goo.gl/s9TGmDtLG5KHZYzG9"><FontAwesomeIcon icon={faGoogle} /></a>
                        <a className='facebook-link' href="https://www.facebook.com/profile.php?id=100071956080812"><FontAwesomeIcon icon={faFacebook} /></a>
                        <a className='tiktok-link' href="https://www.tiktok.com/@chiptuningkrk.pl"><FontAwesomeIcon icon={faTiktok} /></a>
                    </div>
                </div>

                <div className="image-container">
                    <img src={image_1} className="first" alt="Not found"/>
                    <img src={image_2} className="second" alt="Not found"/>
                    <img src={image_3} className="third" alt="Not found"/>
                </div>
            </div>
            <span className="graphic-curve shadow"></span>
            <span className="graphic-curve"></span>
        </div>
    );
}

export default AboutMe;