// Styles
import './style.scss';

import React from 'react';
import { Link as LinkScroll } from "react-scroll"

import background_image from '../../../../image/welcome_page/background.gif';

// Actuall function
const WelcomeSection = () => {

  return (
    <div id='welcome-section'>
        <div className='img-container'>
            <img src={background_image} alt='No path found'/>
        </div>

        <div 
          className="welcome-container"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-once="true"
          data-aos-easing="ease-in-out">
            <div className='container'>
                <h1>Twój samochód może być (<strong>znacznie</strong>) szybszy</h1>
                <p>Chiptuning w Krakowie, indywidualne strojenie silników spalinowych benzynowych i diesla, automatycznych skrzyń biegów, diagnostyka, usuwanie usterek Adblue, DPF, EGR - Hamownia dorgowa Dynomet GPS V2</p>
                <LinkScroll
                  to="about-me-section"
                  smooth={true}
                  duration={400}
                >
                  Dowiedz się więcej
                </LinkScroll>
            </div>
        </div>

      <span className="graphic-curve shadow"></span>
      <span className="graphic-curve"></span>
    </div>
  );
}

export default WelcomeSection;